<template>
  <CardWrapper>
    <ImageUrl :class="`banner-card banner-card-${item.success ? 'primary' : 'dark'}`">
      <div class="banner-card__top align-center-v justify-content-between">
        <h4 class="banner-card__title">
          <span>
            <sdFeatherIcons
              v-if="!item.success"
              type="x-circle"
              size="16"
              color="red"
            />
            <sdFeatherIcons
              v-else
              type="check-circle"
              size="16"
            />
            {{ item.title }}
          </span>
        </h4>
        <!--        <a-badge :count=id />-->
      </div>
      <div class="banner-card__body">
        <p>{{ item.content }}</p>
      </div>
      <div class="banner-card__bottom  align-center-v justify-content-between">
        <div
          v-if="!item.success"
          class="card-author"
        >
          <sdButton
            size="default"
            outlined
            type="light"
            @click="customMethod"
          >
            <sdFeatherIcons
              type="layers"
              size="14"
            />
            <span>{{ item.buttonName }}</span>
          </sdButton>
        </div>
        <div class="card-meta">
          <ul>
            <li v-if="item.success">
              <sdFeatherIcons
                type="check"
                size="16"
              />
              <span class="view-count">Başarılı</span>
            </li>
            <li v-else>
              <a-popover
                v-if="item.popover"
                placement="bottom"
              >
                <template #content>
                  <p v-html="item.popoverInfo" />
                </template>
                <a-button type="primary">
                  <template #icon>
                    <!--                    <DownloadOutlined />-->
                    &#8505;
                  </template>
                </a-button>
              </a-popover>
            </li>
          </ul>
        </div>
      </div>
    </ImageUrl>
  </CardWrapper>
</template>

<script>
import { defineComponent } from 'vue'
import Styled from 'vue3-styled-components'
import VueTypes from 'vue-types'

const CardWrapper = Styled.figure`
    margin-bottom: 0;
    .banner-card{
      padding: 20px 25px 25px 25px;
      border-radius: 10px;
      &.banner-card-primary{
          background-color: ${({ theme }) => theme['primary-color']};
      }
      &.banner-card-dark{
          background-color: ${({ theme }) => theme['dark-color']};
      }
      &.banner-card-border{
          border: 1px solid ${({ theme }) => theme['border-color-light']};
          .banner-card__title{
              color: ${({ theme }) => theme['dark-color']} !important;
          }
          .banner-card__body{
              p{
                  color: ${({ theme }) => theme['gray-color']};
              }
          }
          .banner-card__bottom{
            .author-name{
                color: ${({ theme }) => theme['gray-color']};
            }
            .card-meta{
                li{
                    span{
                        color: ${({ theme }) => theme['extra-light-color']};
                    }
                }
            }
          }
      }
      .banner-card__top {
          .banner-card__title{
              font-size: 16px;
              font-weight: 500;
              display: flex;
              align-items: center;
              color: #fff;
              margin-bottom: 14px;
              img,
              svg,
              i{
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
              }
            }
            .banner-card__action{
                .ant-dropdown-trigger{
                    svg,
                    i{
                        color: ${({ theme }) => theme['extra-light-color']};
                    }
                }
            }
        }
      }
      .banner-card__body{
          p{
              margin-bottom: 20px;
              line-height: 1.786;
              color: #ffffff90;
          }
      }
      .banner-card__bottom {
        .card-author{
            img{
                max-width: 30px;
                border-radius: 50%;
            }
            .author-name{
                ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 15px;
                font-weight: 500;
                color: #ffffff90;
            }
        }
        .card-meta{
            ul{
                display: flex;
                align-items: center;
                li{
                    display: flex;
                    align-items: center;
                    &:not(:last-child){
                        margin-right: 10px;
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
                    }
                    i{
                      line-height: 1;
                    }
                    svg,
                    img{
                        color: ${({ theme }) => theme['extra-light-color']};
                        margin-right: 6px;
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
                        width: 16px;
                    }
                    span{
                        font-size: 13px;
                        color: #fff;
                    }
                }
            }
        }
      }
  }
`
const ImageUrl = Styled.div`
  ${({ bgUrl }) => bgUrl && `background-image: url(${require(`../../static/img/sampleCards/${bgUrl}`)})`};
  background-size: cover;
  background-reapet: no-reapet;
  background-position: center center;
`

export default defineComponent({
  name: 'BannerCard',
  components: { CardWrapper, ImageUrl },
  props: {
    item: VueTypes.object.def({
      success: true,
      title: 'Primary Color',
      content: 'Lorem Ipsum is simply dummy text of the printing printer took a galley of type and scrambled and typesetting industry.',
      buttonName: '',
      popover: false,
      popoverInfo: '',
    }),
  },
  setup(props) {

  },
  methods: {
    customMethod() {
      this.$emit('customEvent', { message: 'Butona tıklandı!' })
    },
  },
})
</script>
