<template>
  <div
    v-if="state.page_loading"
    class="example"
  >
    <a-spin />
  </div>
  <div v-else-if="state.is_ready">
    <sdPageHeader title="Hoşgeldiniz">
      <template #buttons />
    </sdPageHeader>
    <Main>
      <a-row :gutter="12">
        <a-col
          :xxl="5"
          :md="8"
          :sm="12"
          :xs="24"
        >
          <SampleCardSix
            :item="{
              title: state.shipment_stats.total_shipment_count,
              content: 'Toplam Gönderi',
              className: 'primary'
            }"
          >
            <template #more>
              <sdButton
                size="small"
                type="primary"
                @click="handleAllShipment"
              >
                <sdFeatherIcons
                  type="arrow-right"
                />
              </sdButton>
            </template>
          </SampleCardSix>
        </a-col>
        <a-col
          :xxl="5"
          :md="8"
          :sm="12"
          :xs="24"
        >
          <SampleCardSix
            :item="{
              title: state.shipment_stats.total_shipment_initial,
              content: 'Onay Bekleyen',
              className: 'primary'
            }"
          >
            <template #more>
              <sdButton
                size="small"
                type="primary"
                @click="handleShipmentsByState(0)"
              >
                <sdFeatherIcons
                  type="arrow-right"
                />
              </sdButton>
            </template>
          </SampleCardSix>
        </a-col>
        <a-col
          :xxl="5"
          :md="8"
          :sm="12"
          :xs="24"
        >
          <SampleCardSix
            :item="{
              title: state.shipment_stats.total_shipment_on_the_way,
              content: 'Yolda',
              className: 'primary'
            }"
          >
            <template #more>
              <sdButton
                size="small"
                type="primary"
                @click="handleShipmentsByState(1)"
              >
                <sdFeatherIcons
                  type="arrow-right"
                />
              </sdButton>
            </template>
          </SampleCardSix>
        </a-col>
        <a-col
          :xxl="5"
          :md="8"
          :sm="12"
          :xs="24"
        >
          <SampleCardSix
            :item="{
              title: state.shipment_stats.total_shipment_delivered,
              content: 'Teslim Edildi',
              className: 'primary'
            }"
          >
            <template #more>
              <sdButton
                size="small"
                type="primary"
                @click="handleShipmentsByState(2)"
              >
                <sdFeatherIcons
                  type="arrow-right"
                />
              </sdButton>
            </template>
          </SampleCardSix>
        </a-col>
        <a-col
          :xxl="4"
          :md="8"
          :sm="12"
          :xs="24"
        >
          <SampleCardSix
            :item="{
              title: state.shipment_stats.total_shipment_returned,
              content: 'İade Edildi',
              className: 'primary'
            }"
          >
            <template #more>
              <sdButton
                size="small"
                type="primary"
                @click="handleShipmentsByState(3)"
              >
                <sdFeatherIcons
                  type="arrow-right"
                />
              </sdButton>
            </template>
          </SampleCardSix>
        </a-col>
      </a-row>
      <a-row :gutter="12">
        <a-col
          :xxl="10"
          :md="16"
          :sm="24"
          :xs="24"
        >
          <a-card
            :title="`Aktif Kargo: ${state.active_cs_company.name}`"
          >
            <p>
              T-Soft Kargo'yu kullandığınız için ortalama kazancınız şuana kadar:
              <b>{{ state.active_cs_company.customer_earnings }}</b>
            </p>
          </a-card>
        </a-col>
      </a-row>
    </Main>
  </div>
  <div v-else>
    <sdPageHeader title="Bilgilerinizi kontrol ediniz">
      <template #buttons />
    </sdPageHeader>
    <Main>
      <a-row :gutter="20">
        <a-col xs:24>
          <BannerCardStyleWrap>
            <sdCards headless>
              <a-row :gutter="25">
                <a-col
                  :xxl="8"
                  :md="12"
                  class="mb-25"
                >
                  <BannerCard
                    :item="{
                      success: state.controlPanels.controlAddress,
                      title: 'Firma Bilgileri',
                      content:
                        'Firma bilgilerinizi doldurmanız gerekmektedir. Bu bilgiler gönderici bilgileriniz olarak da kullanılacaktır.',
                      buttonName: 'Firma Bilgileri',
                      popover: false,
                    }"
                    @customEvent="handleCompanyInfo"
                  />
                </a-col>
                <a-col
                  :xxl="8"
                  :md="12"
                  class="mb-25"
                >
                  <BannerCard
                    :item="{
                      success: state.controlPanels.controlCredit,
                      title: 'Bakiye Yükle',
                      content:
                        'Minimum bakiye yükleme tutarı: ' + state.min_top_up_balance + ' TL. Gönderi oluşturabilmeniz için bakiye yüklemeniz gerekmektedir.',
                      buttonName: 'Ödeme Yap',
                      popover: !state.controlPanels.controlCredit,
                      popoverInfo:
                        'T-Soft kargo hesabınızdaki ' + state.max_block_limit + ' TL\'yi blokeli olarak tutmaktadır.<br>Bunun sebebi yapmış olduğunuz gönderinin nihai ücreti kesinleşmemesinden kaynaklanmaktadır.<br>T-Soft Kargo bloke tutarı değiştirme hakkını saklı tutar',
                    }"
                    @customEvent="handlePayNow"
                  />
                </a-col>
                <a-col
                  :xxl="8"
                  :md="12"
                  class="mb-25"
                >
                  <a-spin :spinning="state.activation_loading">
                    <BannerCard
                      :item="{
                        success: state.controlPanels.controlActivation,
                        title: 'T-Soft Entegrasyonu',
                        content:
                          'T-Soft entegrasyonu ile kargo gönderimi yapabilmek için entegrasyonu aktif ediniz',
                        buttonName: 'Entegrasyonu Aktif Et',
                        popover: false,
                      }"
                      @customEvent="handleCargoActivation"
                    />
                  </a-spin>
                </a-col>
              </a-row>
            </sdCards>
          </BannerCardStyleWrap>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import { BannerCardStyleWrap, Main } from '../styled'
import BannerCard from '../../components/cards/BannerCard'
import { message, notification } from 'ant-design-vue'
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import restMethods from '@/utility/restMethods'
import SampleCardSix from '@/components/cards/sampleCard/SampleCardSix.vue'

export default {
  name: 'MainPage',
  components: {
    SampleCardSix,
    Main,
    BannerCardStyleWrap,
    BannerCard,
  },
  setup() {

    const router = useRouter()

    const state = reactive({
      page_loading: true,
      is_ready: false,
      activation_loading: false,
      controlPanels: {
        controlAddress: false,
        controlCredit: false,
        controlActivation: false,
      },
      min_top_up_balance: 0,
      max_block_limit: 0,

      shipment_stats: {
        total_shipment_count: 0,
        total_shipment_initial: 0,
        total_shipment_on_the_way: 0,
        total_shipment_delivered: 0,
        total_shipment_returned: 0,
      },

      active_cs_company: {
        name: '',
        customer_earnings: '',
      },
    })

    const showErrorNotification = (message) => {
      notification.error({
        message: 'Hata',
        description: message,
        placement: 'bottomRight',
        duration: 0, // Süresiz gösterim için 0 kullanılır
        style: {
          marginBottom: '50px',
        },
      })
    }

    const handleCompanyInfo = () => {
      router.push({ name: 'O.Settings.Page' })
    }

    const handlePayNow = () => {
      router.push({ name: 'T.PaymentContract.Page' })
    }

    const handleCargoActivation = () => {
      state.activation_loading = true
      restMethods.postForTSoftKargoWebApp('/set-cargo-to-active', { code: 'arasmobil' }).then(response => {
        if ( ! response.hasError()) {
          message.success(response.getData().message)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          state.activation_loading = false // API çağrısı tamamlandıktan sonra yükleme durumunu güncelleyin
        }
      })
    }

    const loadPageData = () => {

      state.page_loading = true

      restMethods.postForTSoftKargoWebApp('/get-contracted-panel-info').then(response => {

        if ( ! response.hasError()) {

          const data = response.getData()

          state.is_ready = data.is_ready

          state.controlPanels.controlActivation = data.controlPanels.controlActivation
          state.controlPanels.controlAddress = data.controlPanels.controlAddress
          state.controlPanels.controlCredit = data.controlPanels.controlCredit

          if (data.checkServices.length > 0) {
            showErrorNotification(data.checkServices)
          }

          state.min_top_up_balance = data.min_top_up_balance
          state.max_block_limit = data.max_block_limit

          state.shipment_stats.total_shipment_count = data.shipment_stats.total_shipment_count
          state.shipment_stats.total_shipment_initial = data.shipment_stats.total_shipment_initial
          state.shipment_stats.total_shipment_on_the_way = data.shipment_stats.total_shipment_on_the_way
          state.shipment_stats.total_shipment_delivered = data.shipment_stats.total_shipment_delivered
          state.shipment_stats.total_shipment_returned = data.shipment_stats.total_shipment_returned

          state.active_cs_company.name = data.active_cs_company.name
          state.active_cs_company.customer_earnings = data.active_cs_company.customer_earnings

        }

        state.page_loading = false

      })

    }

    const handleAllShipment = () => {
      router.push({ name: 'T.ShipmentList.Page' })
    }

    const handleShipmentsByState = (state) => {
      router.push({ name: 'T.ShipmentList.Page', query: { state: state } })
    }

    onMounted(() => {
      loadPageData()
    })

    return {
      state,

      handleCompanyInfo,
      handlePayNow,
      handleCargoActivation,

      handleAllShipment,
      handleShipmentsByState,

    }

  },

}
</script>
<style scoped>
.example {
  text-align: center;
  padding: 30px 50px;
}
</style>