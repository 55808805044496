<template>
  <sdCards headless>
    <CardWrapper>
      <figcaption>
        <div class="more">
          <slot name="more" />
        </div>
        <h2>{{ title }}</h2>
        <p>{{ content }}</p>
      </figcaption>
    </CardWrapper>
  </sdCards>
</template>

<script>
import { defineComponent, toRefs } from 'vue'
import Styled from 'vue3-styled-components'
import VueTypes from 'vue-types'

const CardWrapper = Styled.figure`
  display: flex;
  margin: 0;
  position: relative;
  h2,
  p {
    margin: 0;
  }
  figcaption {
    .more {
      position: absolute;
      top: 0px;
      ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
      a {
        color: ${({ theme }) => theme['extra-light-color']};
      }
    }
    h2 {
      font-size: 20px;
      font-weight: 600;
    }
    p {
      font-size: 14px;
      color: ${({ theme }) => theme['light-color']};
    }
  }
`
const Icon = Styled.div`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, className }) => theme[`${className}-color`]};
  ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 20px;
`

export default defineComponent({
  name: 'SampleCardSix',
  components: { CardWrapper },
  props: {
    item: VueTypes.object.def({
      title: '47',
      content: 'Total tasks',
      className: 'primary',
    }),
  },
  setup(props) {
    const { item } = toRefs(props)
    const { title, content, className } = item.value
    return { title, content, className }
  },
})
</script>
